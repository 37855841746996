import React from 'react'
import PublicLayout from '../../layouts/public'

const Index = () => (
    <PublicLayout>
        <div className="fill-height">
            <div className="container-xl container-max-xl">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 mt-5 text-center">
                        <h1 className="mt-4">Na této adrese jsme nic nenašli <span role="img" aria-label="Opice">🙈</span></h1>
                    </div>
                </div>
            </div>
        </div>
    </PublicLayout>
)

export default Index
