import React from 'react'
import Index from './index'
import withAuth from '../../wrappers/with-auth'
import PropTypes from 'prop-types'
import FormComponent from '../form-component'

class Container extends FormComponent {
    state = {
        email: '',
        password: '',
        isLoading: false,
    }

    /**
     * Gets called on component mounting
     */
    componentDidMount() {
        const { location } = this.props

        if (location?.search === '?error=refresh_token_expired') {
            this.setState({ errorMessage: 'Bezpečnostní odhlášení, přihlašte se prosím znovu' })
        }
    }

    /**
     * Sets error messages
     * @param {Object} err
     */
    setErrorMessage = err => {
        if (err.code === 'NotAuthorizedException') {
            this.setState({ errorMessage: 'Nesprávné přihlašovací údaje' })
        } else if (err.code === 'UserNotFoundException') {
            this.setState({ errorMessage: 'Uživatel nebyl v systému nalezen' })
        } else if (err.code === 'TooManyRequestsException') {
            this.setState({ errorMessage: 'Příliš mnboho pokusů o přihlášení' })
        } else if (err.code === 'NetworkError') {
            this.setState({ errorMessage: 'Chyba připojení' })
        } else {
            console.error(err, err?.code)
        }
    }

    /**
     * Handles form submission
     * @param {Object} event
     */
    handleSubmit = event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        this.props.signInUser(this.state)
            .catch(err => this.setErrorMessage(err))
            .then(() => this.setState({ isLoading: false }))
    }

    /**
     * Renders base component
     * @returns {*}
     */
    render = () => {
        const {
            errorMessage,
            isLoading,
        } = this.state

        return (
            <Index
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                errorMessage={errorMessage}
                isLoading={isLoading}
            />
        )
    }
}

Container.propTypes = {
    signInUser: PropTypes.func.isRequired,
}

export default withAuth(Container)
