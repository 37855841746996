import { Auth } from 'aws-amplify'

/**
 * Checks whether the currently authenticated users credentials have expired
 *
 * @description
 * first if statement checks whether the error is authentication based or not. If it's not, there is a side event
 * that the method is not built for.
 *
 * the second statement checks if there are user credentials present in the memory, if there aren't, the user has
 * either logged out on his own or it's a new user browsing the site before registering
 *
 * if the user is detected in memory but his user credentials are expired, he will be logged out, sent to the login
 * screen with an error saying that the user has been logged out for safety reasons
 */
export const checkUserRefreshTokenExpiration = () => {
    Auth.currentAuthenticatedUser().catch(error => {
        if (error !== 'The user is not authenticated') {
            return
        }

        if (!localStorage.getItem('cognito.user')) {
            return
        }

        Auth.signOut()
            .then(() => {
                localStorage.removeItem('cognito.user')
                localStorage.removeItem('cognito.isAdmin')
                localStorage.removeItem('cognito.isVideoUploader')

                window.location.href = '/login?error=refresh_token_expired'
            })
            .catch(err => console.error(err))
    })
}

checkUserRefreshTokenExpiration()
