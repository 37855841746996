import React from 'react'
import withGraphql from './with-graphql'
import {
    STORE_STREAM,
    UPDATE_STREAM,
    UPDATE_STREAM_THUMBNAIL,
    ACTIVATE_STREAM_ACTIVATION_CODE,
    UPDATE_STREAM_TICKET_TYPES,
    UPDATE_STREAM_IS_PUBLICIZED,
    CREATE_STREAM_RECORD_REPLACEMENT_VIDEO_UPLOAD,
} from '../../graphql/streams/mutations'
import {
    GET_STREAM,
    LIST_AUTH_STREAM_EMAIL_INVITATIONS,
    LIST_STREAMS,
    GET_MUX_STREAM_KEY,
    GET_STREAM_THUMBNAIL_SIGNED_UPLOAD_URL,
    LIST_STREAMS_BY_DATETIME,
    LIST_STREAMS_BY_CHANNEL,
    LIST_MUX_STREAM_RECORDS,
} from '../../graphql/streams/queries'
import appSyncClient from '../../appsync-client'
import publicAppSyncClient from '../../appsync-client-public'
import { STREAM_LIVE_STATUS_UPDATED } from '../../graphql/subscriptions'
import store from '../../store'

const withStreams = WrappedComponent => {
    class childComponent extends React.Component {
        /**
         * Stores stream to dynamoDB
         * @param {Object} stream
         * @returns {Promise}
         */
        storeStream = stream => (
            appSyncClient.mutate({
                mutation: STORE_STREAM,
                variables: {
                    stream: stream,
                },
            })
                .then(data => data.data.storeStream)
        )

        /**
         * Updates stream data
         * @param {string} streamPk
         * @param {Object} stream
         * @returns {Promise}
         */
        updateStream = (streamPk, stream) => (
            appSyncClient.mutate({
                mutation: UPDATE_STREAM,
                variables: {
                    streamPk: streamPk,
                    stream: stream,
                },
            })
                .then(data => data.data.updateStream)
        )

        /**
         * Updates existing stream thumbnail
         * @param {Object} input
         * @returns {Promise}
         */
        updateStreamThumbnail = input => (
            appSyncClient.mutate({
                mutation: UPDATE_STREAM_THUMBNAIL,
                variables: {
                    input: input,
                },
            })
                .then(data => data.data.updateStreamThumbnail)
        )

        /**
         * Updates existing stream ticket types
         * @param {string} pk
         * @param {array} ticketTypes
         * @returns {Promise}
         */
        updateStreamTicketTypes = (pk, ticketTypes) => (
            appSyncClient.mutate({
                mutation: UPDATE_STREAM_TICKET_TYPES,
                variables: {
                    pk,
                    ticketTypes,
                },
            })
                .then(data => data.data.updateStreamTicketTypes)
        )

        /**
         * Updates existing stream is publicized status
         * @param {string} pk
         * @param {boolean} isPublicized
         * @returns {Promise}
         */
        updateStreamIsPublicized = (pk, isPublicized) => (
            appSyncClient.mutate({
                mutation: UPDATE_STREAM_IS_PUBLICIZED,
                variables: {
                    pk,
                    isPublicized,
                },
            })
                .then(data => data.data.updateStreamIsPublicized)
        )

        /**
         * Lists pks of streams owned by auth user
         * @param {Object} queryInput
         * @returns {Promise}
         */
        listAuthStreamEmailInvitations = queryInput => (
            appSyncClient.query({
                query: LIST_AUTH_STREAM_EMAIL_INVITATIONS,
                variables: {
                    queryInput,
                },
            })
                .then(data => ({
                    items: data.data.listAuthStreamEmailInvitations.items.map(item => item.stream),
                }))
        )

        /**
         * Lists streams
         * @param {Object} queryInput
         * @returns {Promise}
         */
        listStreams = queryInput => (
            this.props.appsyncQuery({
                query: LIST_STREAMS,
                name: 'listStreams',
                variables: {
                    queryInput: {
                        ...queryInput,
                        scanIndexForward: false,
                    },
                },
            })
        )

        /**
         * Activates stream activation code
         * @param {string} streamPk
         * @param {string} code
         * @returns {Promise}
         */
        activateStreamActivationCode = (streamPk, code) => (
            this.props.appsyncMutation({
                mutation: ACTIVATE_STREAM_ACTIVATION_CODE,
                name: 'activateStreamActivationCode',
                variables: {
                    streamPk: streamPk,
                    code: code,
                },
            })
        )

        /**
         * Returns mux stream data by it's mux id
         * @param {string} streamMuxId
         * @returns {Promise}
         */
        getMuxStreamKey = streamMuxId => (
            appSyncClient.query({
                query: GET_MUX_STREAM_KEY,
                variables: {
                    streamMuxId: streamMuxId,
                },
            })
                .then(data => data.data.getMuxStreamKey)
        )

        /**
         * Returns stream by streamPk
         * @param {string} streamPk
         * @returns {Promise}
         */
        getStream = streamPk => (
            this.props.appsyncQuery({
                query: GET_STREAM,
                name: 'getStream',
                variables: {
                    streamPk: streamPk,
                },
            })
        )

        /**
         * Lists upcoming streams
         * @param {Object} queryInput
         * @returns {Promise}
         */
        listUpcomingStreams = queryInput => {
            const startDateTime = new Date(Date.now() - 7200000).toISOString()
            const endDateTime = new Date(Date.now() + (2419200000 * 2)).toISOString()

            return publicAppSyncClient.query({
                query: LIST_STREAMS_BY_DATETIME,
                variables: {
                    startDateTime,
                    endDateTime,
                    queryInput: {
                        ...queryInput,
                        scanIndexForward: true,
                    },
                },
            })
                .then(res => res.data?.listStreamsByDateTime)
        }

        /**
         * Lists upcoming streams
         * @param {Object} queryInput
         * @returns {Promise}
         */
        listRecentStreams = queryInput => {
            const startDateTime = new Date(Date.now() - 691200000).toISOString()
            const endDateTime = new Date(Date.now() - 7200000).toISOString()

            return publicAppSyncClient.query({
                query: LIST_STREAMS_BY_DATETIME,
                variables: {
                    startDateTime,
                    endDateTime,
                    queryInput,
                },
            })
                .then(res => res.data?.listStreamsByDateTime)
        }

        /**
         * Lists upcoming streams
         * @param {string} channelSlug
         * @param {int} limit
         * @param {string} lastEvaluatedKey
         * @returns {Promise}
         */
        listChannelUpcomingStreams = (channelSlug, limit, lastEvaluatedKey) => {
            const startDateTime = new Date(Date.now() - 7200000).toISOString()
            const endDateTime = new Date(Date.now() + (2419200000 * 2)).toISOString()

            return publicAppSyncClient.query({
                query: LIST_STREAMS_BY_CHANNEL,
                variables: {
                    channelSlug,
                    startDateTime,
                    endDateTime,
                    queryInput: {
                        limit,
                        lastEvaluatedKey,
                        scanIndexForward: true,
                    },
                },
            })
                .then(res => res.data?.listStreamsByChannel)
        }

        /**
         * Lists upcoming streams
         * @param {string} channelSlug
         * @param {int} limit
         * @param {string} lastEvaluatedKey
         * @returns {Promise}
         */
        listChannelRecentStreams = (channelSlug, limit, lastEvaluatedKey) => {
            const startDateTime = new Date(Date.now() - 691200000).toISOString()
            const endDateTime = new Date(Date.now() - 7200000).toISOString()

            return publicAppSyncClient.query({
                query: LIST_STREAMS_BY_CHANNEL,
                variables: {
                    channelSlug,
                    startDateTime,
                    endDateTime,
                    queryInput: {
                        limit,
                        lastEvaluatedKey,
                    },
                },
            })
                .then(res => res.data?.listStreamsByChannel)
        }

        /**
         * Returns stream by streamPk
         * @param {string} streamPk
         * @param {string} key
         * @param {string} contentType
         * @returns {Promise}
         */
        getStreamThumbnailSignedUploadUrl = (streamPk, key, contentType) => (
            appSyncClient.query({
                query: GET_STREAM_THUMBNAIL_SIGNED_UPLOAD_URL,
                variables: {
                    streamPk: streamPk,
                    key: key,
                    contentType: contentType,
                },
            })
                .then(res => JSON.parse(res.data.getStreamThumbnailSignedUploadUrl))
        )

        /**
         * Subscribes stream live status updating
         * @param {string} streamPk
         * @param {function} callback
         * @returns {*}
         */
        streamLiveStatusUpdated = (streamPk, callback) => {
            const auth = store.getState()?.auth?.user
            const client = auth ? appSyncClient : publicAppSyncClient

            return client.subscribe({
                query: STREAM_LIVE_STATUS_UPDATED,
                variables: {
                    pk: streamPk,
                },
            }).subscribe({
                next: data => callback(data.data.streamLiveStatusUpdated),
                error: error => console.warn(error),
            })
        }

        /**
         * Returns mux stream data by it's mux id
         * @param {string} streamPk
         * @returns {Promise}
         */
        listMuxStreamRecords = streamPk => (
            this.props.appsyncQuery({
                name: 'listMuxStreamRecords',
                query: LIST_MUX_STREAM_RECORDS,
                variables: {
                    streamPk,
                },
            })
                .then(data => JSON.parse(data))
        )

        /**
         * Creates new stream record replacement video upload
         * @param {string} streamPk
         * @return {Promise}
         */
        createStreamRecordReplacementVideoUpload = streamPk => (
            appSyncClient.mutate({
                mutation: CREATE_STREAM_RECORD_REPLACEMENT_VIDEO_UPLOAD,
                variables: {
                    streamPk,
                },
            })
                .then(data => data.data.createStreamRecordReplacementVideoUpload)
        )

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                storeStream={this.storeStream}
                updateStream={this.updateStream}
                getStreamThumbnailSignedUploadUrl={this.getStreamThumbnailSignedUploadUrl}
                updateStreamThumbnail={this.updateStreamThumbnail}
                updateStreamTicketTypes={this.updateStreamTicketTypes}
                updateStreamIsPublicized={this.updateStreamIsPublicized}
                getMuxStreamKey={this.getMuxStreamKey}
                getStream={this.getStream}
                listAuthStreamEmailInvitations={this.listAuthStreamEmailInvitations}
                listStreams={this.listStreams}
                listUpcomingStreams={this.listUpcomingStreams}
                listRecentStreams={this.listRecentStreams}
                activateStreamActivationCode={this.activateStreamActivationCode}
                streamLiveStatusUpdated={this.streamLiveStatusUpdated}
                listChannelUpcomingStreams={this.listChannelUpcomingStreams}
                listChannelRecentStreams={this.listChannelRecentStreams}
                listMuxStreamRecords={this.listMuxStreamRecords}
                createStreamRecordReplacementVideoUpload={this.createStreamRecordReplacementVideoUpload}
                {...this.props}
            />
        )
    }

    return withGraphql(childComponent)
}

export default withStreams
