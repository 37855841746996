import gql from 'graphql-tag'

/**
 * Exports channel
 * @type {any}
 */
export const GET_CHANNEL = gql`
    query query($channelPk: String!) {
        getChannel(channelPk: $channelPk) {
            pk
            slug
            thumbnail
            header_image
            name
            name_en
            description
            description_en
            social_links {
                type
                value
            }
        }
    }
`

/**
 * Exports channel by slug
 * @type {any}
 */
export const GET_CHANNEL_BY_SLUG = gql`
    query query($channelSlug: String!) {
        getChannelBySlug(channelSlug: $channelSlug) {
            pk
            slug
            thumbnail
            header_image
            name
            name_en
            description
            description_en
            social_links {
                type
                value
            }
        }
    }
`

/**
 * Lists channels
 * @type {any}
 */
export const LIST_CHANNELS = gql`
    query query($limit: Int, $lastEvaluatedKey: String) {
        listChannels(limit: $limit, lastEvaluatedKey: $lastEvaluatedKey) {
            items {
                pk
                slug
                thumbnail
                header_image
                name
                name_en
                description
                description_en
                social_links {
                    type
                    value
                }
                created_at
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Returns video thumbnail signed upload url
 * @type {any}
 */
export const GET_CHANNEL_THUMBNAIL_SIGNED_UPLOAD_URL = gql`
    query query($channelSlug: String!, $key: String!, $contentType: String!) {
        getChannelThumbnailSignedUploadUrl(channelSlug: $channelSlug, key: $key, contentType: $contentType)
    }
`

/**
 * Returns video header image signed upload url
 * @type {any}
 */
export const GET_CHANNEL_HEADER_IMAGE_SIGNED_UPLOAD_URL = gql`
    query query($channelSlug: String!, $key: String!, $contentType: String!) {
        getChannelHeaderImageSignedUploadUrl(channelSlug: $channelSlug, key: $key, contentType: $contentType)
    }
`
