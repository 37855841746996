import React from 'react'
import SignInForm from '../forms/sign-in/container'

const Index = () => (
    <div className="container fill-height">
        <div className="row">
            <div className="col-12 max-500 mt-5 text-center">
                <h2 className="mt-3 mb-4">Přihlášení</h2>
            </div>
        </div>
        <div className="row">
            <div className="col-12 max-500">
                <SignInForm />
            </div>
        </div>
    </div>
)

export default Index
