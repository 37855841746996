import gql from 'graphql-tag'

/**
 * Creates new stream
 * @return {string}
 */
export const STORE_STREAM = gql`
    mutation mutation($stream: StreamInput!) {
        storeStream (stream: $stream) {
            pk
            name
            starts_at
            mux_id
            mux_playback_ids
        }
    }
`

/**
 * Updates existing stream
 * @return {string}
 */
export const UPDATE_STREAM = gql`
    mutation mutation($streamPk: String!, $stream: StreamInput!) {
        updateStream (streamPk: $streamPk, stream: $stream) {
            name
            starts_at
            show_recordings
            eshop_link
        }
    }
`

/**
 * Updates existing stream thumbnail
 * @return {string}
 */
export const UPDATE_STREAM_THUMBNAIL = gql`
    mutation mutation($input: StreamUpdateThumbnail!) {
        updateStreamThumbnail(input: $input) {
            pk
            thumbnail
        }
    }
`

/**
 * Activates stream activation code
 * @type {string}
 */
export const ACTIVATE_STREAM_ACTIVATION_CODE = gql`
    mutation activateStreamActivationCode($streamPk: String!, $code: String!) {
        activateStreamActivationCode(streamPk: $streamPk, code: $code)
    }
`

/**
 * Updates existing video ticket types
 * @return {string}
 */
export const UPDATE_STREAM_TICKET_TYPES = gql`
    mutation mutation($pk: String!, $ticketTypes: [TicketTypeInput]!) {
        updateStreamTicketTypes(pk: $pk, ticketTypes: $ticketTypes) {
            pk
            ticket_types {
                name
                description
                link
                price
                currency
            }
        }
    }
`

/**
 * Updates existing stream is publicized boolean stat
 * @return {string}
 */
export const UPDATE_STREAM_IS_PUBLICIZED = gql`
    mutation mutation($pk: String!, $isPublicized: Boolean!) {
        updateStreamIsPublicized(pk: $pk, isPublicized: $isPublicized) {
            pk
            is_publicized
        }
    }
`

/**
 * Creates video upload
 * @type {any}
 */
export const CREATE_STREAM_RECORD_REPLACEMENT_VIDEO_UPLOAD = gql`
    mutation createStreamRecordReplacementVideoUpload($streamPk: String!) {
        createStreamRecordReplacementVideoUpload(streamPk: $streamPk) {
            pk
            url
        }
    }
`
