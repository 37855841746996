import React from 'react'
import Navigation from '../components/navigation'
import { withRouter } from 'react-router'

const Index = ({
    children,
    history,
}) => (
    <div>
        <Navigation history={history} />
        { children }
    </div>
)

export default withRouter(Index)
