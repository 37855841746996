import React from 'react'

const withConfig = WrappedComponent => (
    class extends React.Component {
        /**
         * Returns config value from index.html meta headers
         * @param {string} key
         * @return {string} key value
         */
        config = key => (
            document.getElementsByName(key)[0]
                .getAttribute('content')
        )

        /**
         * Returns current application environment string
         * @returns {string}
         */
        env = () => this.config('REACT_APP_ENV')

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                config={this.config}
                env={this.env}
                { ...this.props }
            />
        )
    }
)

export default withConfig
