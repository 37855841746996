import { createStore } from 'redux'
import reducer from './reducers'

/**
 * Returns item from local storage
 * @param {string} key
 * @returns {null|Object}
 */
const getItemFromLocalStorage = key => localStorage.getItem(key) && localStorage.getItem(key) !== 'undefined' ? JSON.parse(localStorage.getItem(key)) : null

const initialState = {
    auth: {
        user: getItemFromLocalStorage('cognito.user'),
        isAdmin: getItemFromLocalStorage('cognito.isAdmin'),
        isVideoUploader: getItemFromLocalStorage('cognito.isVideoUploader'),
    },
}

const store = createStore(reducer, initialState)

export default store
