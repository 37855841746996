import gql from 'graphql-tag'

/**
 * Returns single video by video pk
 * @type {any}
 */
export const GET_VIDEO = gql`
    query query($videoPk: String!) {
        getVideo(videoPk: $videoPk) {
            pk
            mux_id
            mux_playback_ids
            mux_data {
                status
                token
                playback_ids {
                    id
                    policy
                }
            }
            name
            name_en
            description
            description_en
            categories
            thumbnail
            user_sub
            can_view_video
            is_video_available
            eshop_link
            organiser {
                name
                link
            }
            auth_relationship
            channel_pk
            channel {
                name
                slug
                thumbnail
            }
            ticket_types {
                name
                name_en
                description
                description_en
                link
                price
                currency
            }
            available_from
            available_to
            skip_seconds
            is_publicized
            is_featured
            created_at
            updated_at
        }
    }
`

/**
 * Lists all videos
 * @type {any}
 */
export const LIST_VIDEOS = gql`
    query query($filter: ListVideosFilter, $input: QueryInput!) {
        listVideos(filter: $filter, input: $input) {
            items {
                pk
                mux_id
                name
                name_en
                description
                description_en
                categories
                thumbnail
                user_sub
                can_view_video
                eshop_link
                organiser {
                    name
                    link
                }
                channel {
                    name
                    slug
                    thumbnail
                }
                available_from
                available_to
                created_at
                updated_at
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Lists available videos
 * @type {any}
 */
export const LIST_AVAILABLE_VIDEOS = gql`
    query query($filter: ListVideosFilter, $input: QueryInput!) {
        listAvailableVideos(filter: $filter, input: $input) {
            items {
                pk
                mux_id
                name
                name_en
                description
                description_en
                categories
                thumbnail
                user_sub
                can_view_video
                eshop_link
                organiser {
                    name
                    link
                }
                channel {
                    name
                    slug
                    thumbnail
                }
                available_from
                available_to
                created_at
                updated_at
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Lists videos by specific channel slug
 * @type {any}
 */
export const LIST_VIDEOS_BY_CHANNEL = gql`
    query query($channelSlug: String!, $limit: Int, $lastEvaluatedKey: String) {
        listVideosByChannel(channelSlug: $channelSlug, limit: $limit, lastEvaluatedKey: $lastEvaluatedKey) {
            items {
                pk
                mux_id
                name
                name_en
                description
                description_en
                categories
                thumbnail
                user_sub
                can_view_video
                eshop_link
                organiser {
                    name
                    link
                }
                channel {
                    name
                    slug
                    thumbnail
                }
                available_from
                available_to
                created_at
                updated_at
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Returns video thumbnail signed upload url
 * @type {any}
 */
export const GET_VIDEO_THUMBNAIL_SIGNED_UPLOAD_URL = gql`
    query query($videoPk: String!, $key: String!, $contentType: String!) {
        getVideoThumbnailSignedUploadUrl(videoPk: $videoPk, key: $key, contentType: $contentType)
    }
`
