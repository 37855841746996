import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Redirect,
} from 'react-router-dom'

const AdminRoute = ({
    component: Component,
    ...rest
}) => {
    const canView = rest.auth && rest.isAdmin

    if (!canView) {
        return (
            <Route
                {...rest}
                render={props => (
                    <Redirect to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }} />
                )}
            />
        )
    }

    return <Route {...rest} render={props => <Component {...props} />} />
}

const mapStateToProps = state => ({
    auth: state?.auth?.user,
    isAdmin: state?.auth?.isAdmin,
})

export default connect(mapStateToProps, {})(AdminRoute)
