import gql from 'graphql-tag'

/**
 * Creates new channel
 * @return {string}
 */
export const STORE_CHANNEL = gql`
    mutation mutation($channel: ChannelInput!) {
        storeChannel(channel: $channel) {
            pk
            name
            name_en
            description
            description_en
        }
    }
`

/**
 * Updates existing video
 * @type {any}
 */
export const UPDATE_CHANNEL = gql`
    mutation mutation($channelSlug: String!, $channel: ChannelInput!) {
        updateChannel(channelSlug: $channelSlug, channel: $channel) {
            name
            name_en
            description
            description_en
        }
    }
`

/**
 * Updates existing channel thumbnail
 * @return {string}
 */
export const UPDATE_CHANNEL_THUMBNAIL = gql`
    mutation mutation($input: ChannelUpdateThumbnail!) {
        updateChannelThumbnail(input: $input) {
            pk
            thumbnail
        }
    }
`

/**
 * Updates existing channel header image
 * @return {string}
 */
export const UPDATE_CHANNEL_HEADER_IMAGE = gql`
    mutation mutation($input: ChannelUpdateHeaderImage!) {
        updateChannelHeaderImage(input: $input) {
            pk
            thumbnail
        }
    }
`
