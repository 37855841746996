import React from 'react'
import { compose } from 'redux'
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'

import withConfig from './wrappers/with-config'

import LoginView from './views/login'

import StreamsIndexView from './views/streams'
import StreamsShowView from './views/streams/show'

import VideosIndexView from './views/videos'
import VideosShowView from './views/videos/show'

import ChannelsIndexView from './views/channels'

import PageNotFound from './views/errors/page-not-found'

import AdminRoute from './routes/admin-route'

class App extends React.Component {
    /**
     * Renders views
     * @returns {*}
     */
    render = () => (
        <Switch>
            <Route exact path='/login' component={LoginView} />

            <AdminRoute exact path='/streams' component={StreamsIndexView} />
            <AdminRoute exact path='/streams/:streamPk' component={StreamsShowView} />

            <AdminRoute exact path='/videos' component={VideosIndexView} />
            <AdminRoute exact path='/videos/:videoPk' component={VideosShowView} />

            <AdminRoute exact path='/channels' component={ChannelsIndexView} />

            <Route exact path='/'><Redirect to='/streams'/></Route>
            <Route exact path='/index.html'><Redirect to='/streams'/></Route>
            <Route component={PageNotFound} />
        </Switch>
    )
}

export default compose(
    withConfig,
)(App)
