import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PageLoader from '../../components/page-loader'
import withVideos from '../../wrappers/with-videos'
import withConfig from '../../wrappers/with-config'
import PublicLayout from '../../layouts/public'

class Container extends React.Component {
    state = {
        video: null,
        isLoading: false,
    }

    /**
     * Gets called on component mounting
     */
    componentDidMount() {
        this.setState({ isLoading: true })

        const {
            match: {
                params: {
                    videoPk,
                },
            },
            getVideo,
        } = this.props

        getVideo(videoPk)
            .then(video => {
                this.setState({
                    video,
                    isLoading: false,
                })
            })
    }

    /**
     * Renders base component
     * @return {JSX.Element}
     */
    render = () => {
        const {
            video,
            isLoading,
        } = this.state

        const { history } = this.props

        return (
            <PublicLayout>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary" onClick={() => history.goBack()}>
                                Zpět
                            </button>
                        </div>
                    </div>
                </div>
                {isLoading &&
                    <PageLoader />
                }
                {!isLoading &&
                    <div className="container my-3">
                        <div className="row">
                            <div className="col-12 my-3">
                                <h1>
                                    { video?.name }
                                </h1>
                            </div>
                            <div className="col-12">
                                <p><b>Mux ID videa:</b> { video?.mux_id }</p>
                            </div>
                        </div>
                    </div>
                }
            </PublicLayout>
        )
    }

}

Container.propTypes = {
    getVideo: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default compose(
    withVideos,
    withConfig,
    withRouter,
)(Container)
