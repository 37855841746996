import React from 'react'
import appSyncClient from '../../appsync-client'
import appSyncClientPublic from '../../appsync-client-public'
import { PUBLISH_STREAM_RECORD } from '../../graphql/mutations'
import {
    UPDATE_VIDEO,
    CREATE_VIDEO_UPLOAD,
    UPDATE_VIDEO_THUMBNAIL,
    UPDATE_VIDEO_TICKET_TYPES,
    UPDATE_VIDEO_IS_PUBLICIZED,
    UPDATE_VIDEO_IS_FEATURED,
} from '../../graphql/videos/mutations'
import {
    GET_VIDEO_THUMBNAIL_SIGNED_UPLOAD_URL,
    GET_VIDEO,
    LIST_VIDEOS,
    LIST_VIDEOS_BY_CHANNEL,
    LIST_AVAILABLE_VIDEOS,
} from '../../graphql/videos/queries'
import withGraphql from './with-graphql'

const withVideos = WrappedComponent => {
    class childComponent extends React.Component {
        /**
         * Creates video upload
         * @param {Object} input
         * @returns {Promise<*>}
         */
        createVideoUpload = input => (
            appSyncClient.mutate({
                mutation: CREATE_VIDEO_UPLOAD,
                variables: {
                    input: input,
                },
            })
                .then(data => data.data.createVideoUpload)
        )

        /**
         * Updates existing video
         * @param {string} videoPk
         * @param {Object} input
         * @returns {Promise<*>}
         */
        updateVideo = (videoPk, input) => (
            appSyncClient.mutate({
                mutation: UPDATE_VIDEO,
                variables: {
                    videoPk,
                    input,
                },
            })
                .then(data => data.data.updateVideo)
        )

        /**
         * Updates existing video ticket types
         * @param {string} pk
         * @param {array} ticketTypes
         * @returns {Promise}
         */
        updateVideoTicketTypes = (pk, ticketTypes) => (
            appSyncClient.mutate({
                mutation: UPDATE_VIDEO_TICKET_TYPES,
                variables: {
                    pk,
                    ticketTypes,
                },
            })
                .then(data => data.data.updateVideoTicketTypes)
        )

        /**
         * Updates videos is publicized attribute
         * @param {string} pk
         * @param {boolean} isPublicized
         * @returns {Promise}
         */
        updateVideoIsPublicized = (pk, isPublicized) => (
            appSyncClient.mutate({
                mutation: UPDATE_VIDEO_IS_PUBLICIZED,
                variables: {
                    pk,
                    isPublicized,
                },
            })
                .then(data => data.data.updateVideoIsPublicized)
        )

        /**
         * Updates video is featured attribute
         * @param {string} pk
         * @param {boolean} isFeatured
         * @returns {Promise}
         */
        updateVideoIsFeatured = (pk, isFeatured) => (
            appSyncClient.mutate({
                mutation: UPDATE_VIDEO_IS_FEATURED,
                variables: {
                    pk,
                    isFeatured,
                },
            })
                .then(data => data.data.updateVideoIsFeatured)
        )

        /**
         * Returns single video
         * @param {string} videoPk
         * @return {Promise}
         */
        getVideo = videoPk => (
            this.props.appsyncQuery({
                query: GET_VIDEO,
                name: 'getVideo',
                variables: {
                    videoPk: videoPk,
                },
            })
        )

        /**
         * Lists videos
         * @param {Object} filter
         * @param {Object} input
         * @return {Promise}
         */
        listVideos = (filter, input) => (
            this.props.appsyncQuery({
                query: LIST_VIDEOS,
                name: 'listVideos',
                variables: {
                    filter,
                    input,
                },
            })
        )

        /**
         * Lists available videos
         * @param {Object} filter
         * @param {Object} input
         * @return {Promise}
         */
        listAvailableVideos = (filter, input) => (
            appSyncClientPublic.query({
                query: LIST_AVAILABLE_VIDEOS,
                variables: {
                    filter,
                    input,
                },
            })
                .then(data => data.data.listAvailableVideos)
        )

        /**
         * Lists videos by channel
         * @param {string} channelSlug
         * @param {int} limit
         * @param {string|null} lastEvaluatedKey
         * @returns {Promise<*>}
         */
        listVideosByChannel = (channelSlug, limit, lastEvaluatedKey) => (
            appSyncClientPublic.query({
                query: LIST_VIDEOS_BY_CHANNEL,
                variables: {
                    channelSlug,
                    limit,
                    lastEvaluatedKey,
                },
            })
                .then(data => data.data.listVideosByChannel)
        )

        /**
         * Returns video by videoPk
         * @param {string} videoPk
         * @param {string} key
         * @param {string} contentType
         * @returns {Promise}
         */
        getVideoThumbnailSignedUploadUrl = (videoPk, key, contentType) => (
            appSyncClient.query({
                query: GET_VIDEO_THUMBNAIL_SIGNED_UPLOAD_URL,
                variables: {
                    videoPk: videoPk,
                    key: key,
                    contentType: contentType,
                },
            })
                .then(res => JSON.parse(res.data.getVideoThumbnailSignedUploadUrl))
        )

        /**
         * Updates existing video thumbnail
         * @param {Object} input
         * @returns {Promise}
         */
        updateVideoThumbnail = input => (
            appSyncClient.mutate({
                mutation: UPDATE_VIDEO_THUMBNAIL,
                variables: {
                    input: input,
                },
            })
                .then(data => data.data.updateVideoThumbnail)
        )

        /**
         * Publishes stream record
         * @param {Object} input
         * @returns {Promise<*>}
         */
        publishStreamRecord = input => (
            appSyncClient.mutate({
                mutation: PUBLISH_STREAM_RECORD,
                variables: {
                    input: input,
                },
            })
                .then(data => data.data.publishStreamRecord)
        )

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                createVideoUpload={this.createVideoUpload}
                updateVideo={this.updateVideo}
                updateVideoTicketTypes={this.updateVideoTicketTypes}
                updateVideoIsPublicized={this.updateVideoIsPublicized}
                updateVideoIsFeatured={this.updateVideoIsFeatured}
                getVideo={this.getVideo}
                listVideos={this.listVideos}
                listAvailableVideos={this.listAvailableVideos}
                getVideoThumbnailSignedUploadUrl={this.getVideoThumbnailSignedUploadUrl}
                updateVideoThumbnail={this.updateVideoThumbnail}
                publishStreamRecord={this.publishStreamRecord}
                listVideosByChannel={this.listVideosByChannel}
                {...this.props}
            />
        )
    }

    return withGraphql(childComponent)
}

export default withVideos
