import React from 'react'

const Index = () => (
    <div className="container-xxl">
        <div className="row mt-4">
            <div className="col-12 mt-5 text-center">
                <div className="spinner-border spinner-border-sm" role="status" />
            </div>
        </div>
    </div>
)

export default Index
