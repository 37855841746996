import gql from 'graphql-tag'

/**
 * Creates video upload
 * @type {any}
 */
export const CREATE_VIDEO_UPLOAD = gql`
    mutation createVideoUpload($input: VideoInput!) {
        createVideoUpload(input: $input) {
            pk
            url
        }
    }
`

/**
 * Updates existing video
 * @type {any}
 */
export const UPDATE_VIDEO = gql`
    mutation mutation($videoPk: String!, $input: VideoInput!) {
        updateVideo (videoPk: $videoPk, input: $input) {
            name
            name_en
            description
            description_en
            categories
            skip_seconds
        }
    }
`

/**
 * Updates existing video thumbnail
 * @return {string}
 */
export const UPDATE_VIDEO_THUMBNAIL = gql`
    mutation mutation($input: VideoUpdateThumbnail!) {
        updateVideoThumbnail(input: $input) {
            pk
            thumbnail
        }
    }
`

/**
 * Updates existing video ticket types
 * @return {string}
 */
export const UPDATE_VIDEO_TICKET_TYPES = gql`
    mutation mutation($pk: String!, $ticketTypes: [TicketTypeInput]!) {
        updateVideoTicketTypes(pk: $pk, ticketTypes: $ticketTypes) {
            pk
            ticket_types {
                name
                description
                link
                price
                currency
            }
        }
    }
`

/**
 * Updates existing video is publicized boolean stat
 * @return {string}
 */
export const UPDATE_VIDEO_IS_PUBLICIZED = gql`
    mutation mutation($pk: String!, $isPublicized: Boolean!) {
        updateVideoIsPublicized(pk: $pk, isPublicized: $isPublicized) {
            pk
            is_publicized
        }
    }
`

/**
 * Updates existing video is featured boolean stat
 * @return {string}
 */
export const UPDATE_VIDEO_IS_FEATURED = gql`
    mutation mutation($pk: String!, $isFeatured: Boolean!) {
        updateVideoIsFeatured(pk: $pk, isFeatured: $isFeatured) {
            pk
            is_featured
        }
    }
`
