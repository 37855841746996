import React from 'react'
import PropTypes from 'prop-types'
import { compose} from 'redux'
import withChannels from '../../wrappers/with-channels'
import withConfig from '../../wrappers/with-config'
import PublicLayout from '../../layouts/public'

class Container extends React.Component {
    state = {
        channels: [],
        lastEvaluatedKey: '',
    }

    /**
     * Gets called on component mounting
     */
    componentDidMount() {
        this.fetchChannels()
    }

    /**
     * Fetches items
     * @param lastEvaluatedKey
     */
    fetchChannels = lastEvaluatedKey => {
        this.props.listChannels(100, lastEvaluatedKey)
            .then(({ items, lastEvaluatedKey }) => {
                this.setState({
                    channels: items,
                    lastEvaluatedKey,
                })
            })
    }

    /**
     * Renders base component
     * @return {JSX.Element}
     */
    render = () => {
        const {
            channels,
            lastEvaluatedKey
        } = this.state

        const { config } = this.props

        return (
            <PublicLayout>
                <div className="container my-3">
                    <div className="row">
                        <div className="col-12 my-3">
                            <h1>
                                Kanály
                            </h1>
                        </div>
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Název</th>
                                    <th scope="col">Vytvořeno</th>
                                    <th scope="col">Akce</th>
                                </tr>
                                </thead>
                                <tbody>
                                {channels.map((channel, index) => (
                                    <tr key={index + 1}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{ channel.name }</td>
                                        <td>{ channel.created_at }</td>
                                        <td>
                                            <a
                                                href={`${config('REACT_APP_DOMAIN')}/channels/${channel.slug}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                streamio
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        {lastEvaluatedKey &&
                            <div className="col-12 text-center">
                                <button
                                    className={"btn btn-primary"}
                                    onClick={() => this.fetchChannels(lastEvaluatedKey)}
                                >
                                    Načíst další
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </PublicLayout>
        )
    }

}

Container.propTypes = {
    listChannels: PropTypes.func.isRequired,
}

export default compose(
    withChannels,
    withConfig,
)(Container)