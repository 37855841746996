import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PageLoader from '../../components/page-loader'
import withStreams from '../../wrappers/with-streams'
import withConfig from '../../wrappers/with-config'
import PublicLayout from '../../layouts/public'

class Container extends React.Component {
    state = {
        stream: null,
        streamRecords: [],
        isLoading: false,
    }

    /**
     * Gets called on component mounting
     */
    componentDidMount() {
        this.setState({ isLoading: true })

        const {
            match: {
                params: {
                    streamPk,
                },
            },
            getStream,
            listMuxStreamRecords,
        } = this.props

        getStream(streamPk)
            .then(stream => {
                this.setState({
                    stream,
                    isLoading: false,
                })
            })

        listMuxStreamRecords(streamPk)
            .then(streamRecords => {
                this.setState({ streamRecords })
            })
    }

    /**
     * Renders base component
     * @return {JSX.Element}
     */
    render = () => {
        const {
            stream,
            streamRecords,
            isLoading,
        } = this.state

        const { history } = this.props

        return (
            <PublicLayout>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary" onClick={() => history.goBack()}>
                                Zpět
                            </button>
                        </div>
                    </div>
                </div>
                {isLoading &&
                    <PageLoader />
                }
                {!isLoading &&
                    <div className="container my-3">
                        <div className="row">
                            <div className="col-12 my-3">
                                <h1>
                                    { stream?.name }
                                </h1>
                            </div>
                            <div className="col-12">
                                <p><b>Mux ID streamu:</b> { stream?.mux_id }</p>
                                <p><b>ID záznamů streamu:</b></p>
                                { streamRecords.map((streamRecord, index) => (
                                    <p key={index}>{ streamRecord.id }</p>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </PublicLayout>
        )
    }

}

Container.propTypes = {
    getStream: PropTypes.func.isRequired,
    listMuxStreamRecords: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default compose(
    withStreams,
    withConfig,
    withRouter,
)(Container)
