import Amplify from 'aws-amplify'
import config from './config'

Amplify.configure({
    Auth: {
        region: config('REACT_APP_AWS_REGION'),
        userPoolId: config('REACT_APP_COGNITO_USER_POOL_ID'),
        userPoolWebClientId: config('REACT_APP_COGNITO_CLIENT_ID'),
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
})
