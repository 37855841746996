import React from 'react'
import appSyncClient from '../../appsync-client'
import {
    STORE_CHANNEL,
    UPDATE_CHANNEL,
    UPDATE_CHANNEL_HEADER_IMAGE,
    UPDATE_CHANNEL_THUMBNAIL,
} from '../../graphql/channels/mutations'
import {
    GET_CHANNEL,
    GET_CHANNEL_BY_SLUG,
    GET_CHANNEL_HEADER_IMAGE_SIGNED_UPLOAD_URL,
    GET_CHANNEL_THUMBNAIL_SIGNED_UPLOAD_URL,
    LIST_CHANNELS,
} from '../../graphql/channels/queries'
import appSyncClientPublic from '../../appsync-client-public'

const withChannels = WrappedComponent => (
    class extends React.Component {
        /**
         * Stores channel to dynamoDB
         * @param {Object} channel
         * @returns {Promise}
         */
        storeChannel = channel => (
            appSyncClient.mutate({
                mutation: STORE_CHANNEL,
                variables: {
                    channel,
                },
            })
                .then(data => data.data.storeChannel)
        )

        /**
         * Returns channel by slug
         * @param {string} channelPk
         * @returns {Promise<*>}
         */
        getChannel = channelPk => (
            appSyncClientPublic.query({
                query: GET_CHANNEL,
                variables: {
                    channelPk,
                },
            })
                .then(data => {
                    console.log(data)
                    return data.data.getChannel
                })
        )

        /**
         * Returns channel by slug
         * @param {string} channelSlug
         * @returns {Promise<*>}
         */
        getChannelBySlug = channelSlug => (
            appSyncClientPublic.query({
                query: GET_CHANNEL_BY_SLUG,
                variables: {
                    channelSlug,
                },
            })
                .then(data => data.data.getChannelBySlug)
        )

        /**
         * Updates channel
         * @param {string} channelSlug
         * @param {Object} channel
         * @returns {Promise<*>}
         */
        updateChannel = (channelSlug, channel) => (
            appSyncClient.mutate({
                mutation: UPDATE_CHANNEL,
                variables: {
                    channelSlug,
                    channel,
                },
            })
                .then(data => data.data.updateChannel)
        )

        /**
         * Lists channels
         * @param {int} limit
         * @param {string|null} lastEvaluatedKey
         * @returns {*}
         */
        listChannels = (limit = 20, lastEvaluatedKey = null) => (
            appSyncClientPublic.query({
                query: LIST_CHANNELS,
                variables: {
                    limit,
                    lastEvaluatedKey,
                },
            })
                .then(data => data.data.listChannels)
        )

        /**
         * Returns channel by channelSlug
         * @param {string} channelSlug
         * @param {string} key
         * @param {string} contentType
         * @returns {Promise}
         */
        getChannelThumbnailSignedUploadUrl = (channelSlug, key, contentType) => (
            appSyncClient.query({
                query: GET_CHANNEL_THUMBNAIL_SIGNED_UPLOAD_URL,
                variables: {
                    channelSlug,
                    key,
                    contentType,
                },
            })
                .then(res => JSON.parse(res.data.getChannelThumbnailSignedUploadUrl))
        )

        /**
         * Updates existing channel thumbnail
         * @param {Object} input
         * @returns {Promise}
         */
        updateChannelThumbnail = input => (
            appSyncClient.mutate({
                mutation: UPDATE_CHANNEL_THUMBNAIL,
                variables: {
                    input,
                },
            })
                .then(data => data.data.updateChannelThumbnail)
        )

        /**
         * Returns channel by channelSlug
         * @param {string} channelSlug
         * @param {string} key
         * @param {string} contentType
         * @returns {Promise}
         */
        getChannelHeaderImageSignedUploadUrl = (channelSlug, key, contentType) => (
            appSyncClient.query({
                query: GET_CHANNEL_HEADER_IMAGE_SIGNED_UPLOAD_URL,
                variables: {
                    channelSlug,
                    key,
                    contentType,
                },
            })
                .then(res => JSON.parse(res.data.getChannelHeaderImageSignedUploadUrl))
        )

        /**
         * Updates existing channel thumbnail
         * @param {Object} input
         * @returns {Promise}
         */
        updateChannelHeaderImage = input => (
            appSyncClient.mutate({
                mutation: UPDATE_CHANNEL_HEADER_IMAGE,
                variables: {
                    input,
                },
            })
                .then(data => data.data.updateChannelHeaderImage)
        )

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                storeChannel={this.storeChannel}
                getChannel={this.getChannel}
                getChannelBySlug={this.getChannelBySlug}
                updateChannel={this.updateChannel}
                listChannels={this.listChannels}
                getChannelThumbnailSignedUploadUrl={this.getChannelThumbnailSignedUploadUrl}
                updateChannelThumbnail={this.updateChannelThumbnail}
                getChannelHeaderImageSignedUploadUrl={this.getChannelHeaderImageSignedUploadUrl}
                updateChannelHeaderImage={this.updateChannelHeaderImage}
                { ...this.props }
            />
        )
    }
)

export default withChannels
