import {
    SET_COGNITO_USER,
    SET_COGNITO_GROUPS,
} from '../actions'

/**
 * Sets cognito user
 * @param {Object} state
 * @param {Object} userData
 * @returns {{user: {sub: *, email: *}}}
 */
const setCognitoUser = (state, userData) => ({
    ...state,
    user: {
        sub: userData.attributes.sub,
        email: userData.attributes.email,
        givenName: userData.attributes.given_name,
        familyName: userData.attributes.family_name,
    },
})

/**
 * Sets cognito is admin store boolean
 * @param {Object} state
 * @param {boolean} isAdmin
 * @param {boolean} isVideoUploader
 * @returns {{isAdmin: *}}
 */
const setCognitoGroups = (state, isAdmin, isVideoUploader) => ({
    ...state,
    isAdmin: isAdmin,
    isVideoUploader: isVideoUploader,
})

/**
 * Auth reducers
 * @param {Object|null} state
 * @param {Object} action
 * @returns {{user: {sub: *, email: *}}|{isAdmin: *}|{user: null}|*}
 */
const auth = (state = null, action) => {
    switch (action.type) {
        case SET_COGNITO_USER: return setCognitoUser(state, action.userData)
        case SET_COGNITO_GROUPS: return setCognitoGroups(state, action.isAdmin, action.isVideoUploader)
        default: return state
    }
}

export default auth
