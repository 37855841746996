import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import config from './config'
import { Auth } from 'aws-amplify'

/**
 * Returns user tokens
 * @returns {*}
 */
const getUserTokens = async() => {
    const session = await Auth.currentSession()

    return session
        .getIdToken()
        .getJwtToken()
}

/**
 * Appsync client itself
 */
export default new AWSAppSyncClient({
    url: config('REACT_APP_APPSYNC_URL'),
    region: config('REACT_APP_AWS_REGION'),
    auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: getUserTokens,
    },
    cacheOptions: {
        addTypename: false,
    },
    disableOffline: true,
}, {
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    },
})
