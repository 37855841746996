import gql from 'graphql-tag'

/**
 * Returns mux stream key
 * @type {any}
 */
export const GET_MUX_STREAM_KEY = gql`
    query query($streamMuxId: String!) {
        getMuxStreamKey(streamMuxId: $streamMuxId)
    }
`

/**
 * Returns mux stream data
 * @type {any}
 */
export const LIST_MUX_STREAM_RECORDS = gql`
    query query($streamPk: String!) {
        listMuxStreamRecords(streamPk: $streamPk)
    }
`

/**
 * Returns stream by pk
 * @type {any}
 */
export const GET_STREAM = gql`
    query query($streamPk: String!) {
        getStream(streamPk: $streamPk) {
            pk
            name
            name_en
            starts_at
            mux_id
            mux_playback_ids
            mux_data {
                status
                token
                playback_ids {
                    id
                    policy
                }
                simulcast_targets {
                    id
                    url
                    stream_key
                    status
                    passthrough
                }
            }
            is_live
            is_public
            show_recordings
            eshop_link
            chat_link
            chat_show
            chat_permission
            thumbnail
            description
            description_en
            auth_ticket_type
            expected_length
            can_view_stream
            organiser {
                name
                link
            }
            channel_pk
            channel {
                name
                slug
                thumbnail
            }
            ticket_types {
                name
                name_en
                description
                description_en
                link
                price
                currency
            }
            is_publicized
        }
    }
`

/**
 * Returns all streams the auth user has access to (by user invitation)
 * @type {any}
 */
export const LIST_AUTH_STREAM_EMAIL_INVITATIONS = gql`
    query query($queryInput: QueryInput) {
        listAuthStreamEmailInvitations(queryInput: $queryInput) {
            items {
                pk
                stream {
                    pk
                    name
                    name_en
                    starts_at
                    mux_id
                    mux_playback_ids
                    thumbnail
                    is_live
                    channel_pk
                    channel {
                        name
                        slug
                        thumbnail
                    }
                    organiser {
                        name
                    }
                }
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Returns all streams
 * @type {any}
 */
export const LIST_STREAMS = gql`
    query query($queryInput: QueryInput) {
        listStreams(queryInput: $queryInput) {
            items {
                pk
                name
                name_en
                starts_at
                mux_id
                mux_playback_ids
                thumbnail
                is_live
                channel_pk
                channel {
                    name
                    slug
                    thumbnail
                }
                organiser {
                    name
                }
                created_at
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Returns streams by specific start date and end date
 * @type {any}
 */
export const LIST_STREAMS_BY_DATETIME = gql`
    query query($startDateTime: String!, $endDateTime: String!, $queryInput: QueryInput) {
        listStreamsByDateTime(startDateTime: $startDateTime, endDateTime: $endDateTime, queryInput: $queryInput) {
            items {
                pk
                name
                name_en
                starts_at
                eshop_link
                thumbnail
                is_live
                channel_pk
                channel {
                    name
                    slug
                    thumbnail
                }
                organiser {
                    name
                }
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Lists streams by specific channel slug
 * @type {any}
 */
export const LIST_STREAMS_BY_CHANNEL = gql`
    query query($channelSlug: String!, $startDateTime: String!, $endDateTime: String!, $queryInput: QueryInput) {
        listStreamsByChannel(channelSlug: $channelSlug, startDateTime: $startDateTime, endDateTime: $endDateTime, queryInput: $queryInput) {
            items {
                pk
                name
                name_en
                starts_at
                eshop_link
                thumbnail
                is_live
                channel_pk
                channel {
                    name
                    slug
                    thumbnail
                }
                organiser {
                    name
                }
            }
            lastEvaluatedKey
        }
    }
`

/**
 * Returns stream thumbnail signed upload url
 * @type {any}
 */
export const GET_STREAM_THUMBNAIL_SIGNED_UPLOAD_URL = gql`
    query query($streamPk: String!, $key: String!, $contentType: String!) {
        getStreamThumbnailSignedUploadUrl(streamPk: $streamPk, key: $key, contentType: $contentType)
    }
`

