// TODO: config should not be a global helper
// it should be inside a HOC wrapper so it can be reused in standardized components
// It's only like this for now to make the deploy builds for different environments simpler
// in future, this will be moved into an HOC component and the entire AWS configuration will also
// be initialized inside this component from index.js
// OR we might create a configuration service
// OR we might use react hooks
// - i will have to come back to this when the MVP is done
// if you read all of this, you have no life, go eat a cookie or smh
// DISCLAIMER: if you need to use config inside your components, use with-config.jsx HOC wrapper

/**
 * Returns config value from index.html meta headers
 * @param {string} key
 * @return {string} key value
 */
const config = key => (
    document.getElementsByName(key)[0]
        .getAttribute('content')
)

export default config
