import React from 'react'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import store from '../../store'
import {
    COGNITO_GROUP_ADMINS,
    COGNITO_GROUP_VIDEO_UPLOADERS,
} from '../../consts'
import {
    SET_COGNITO_USER,
    SET_COGNITO_GROUPS,
} from '../../actions'
import PropTypes from 'prop-types'

const withAuth = WrappedComponent => {
    class childComponent extends React.Component {
        /**
         * Sets authenticated user
         * @param {Object} data
         */
        setAuthenticatedUser = data => {
            store.dispatch({
                type: SET_COGNITO_USER,
                userData: data,
            })

            localStorage.setItem('cognito.user', JSON.stringify(store.getState().auth.user))
        }

        /**
         * Sets authenticated user roles
         * @param {Object} data
         */
        setAuthenticatedUserRoles = data => {
            const groups = data.signInUserSession?.accessToken?.payload['cognito:groups']
            const isAdmin = groups ? groups.includes(COGNITO_GROUP_ADMINS) : false
            const isVideoUploader = groups ? groups.includes(COGNITO_GROUP_VIDEO_UPLOADERS) : false

            store.dispatch({
                type: SET_COGNITO_GROUPS,
                isAdmin,
                isVideoUploader,
            })

            localStorage.setItem('cognito.isAdmin', JSON.stringify(isAdmin))
            localStorage.setItem('cognito.isVideoUploader', JSON.stringify(isVideoUploader))
        }

        /**
         * Registers user
         * @param {Object} userData
         * @returns {Promise}
         */
        signUpUser = userData => (
            Auth.signUp({
                username: userData.email.toLowerCase(),
                password: userData.password,
                attributes: {
                    given_name: userData.given_name,
                    family_name: userData.family_name,
                },
            })
                .then(() => this.signInUser({
                    email: userData.email,
                    password: userData.password,
                }))
                .catch(err => Promise.reject(err))
        )

        /**
         * Redirects user after sign in
         * @returns {*}
         */
        redirectAfterSignIn = () => {
            const { history } = this.props

            if (localStorage.getItem('auth.before.route')) {
                history.push(localStorage.getItem('auth.before.route'))
                return localStorage.removeItem('auth.before.route')
            }

            return history.push('/streams')
        }

        /**
         * Signs in user
         * @param {null|string} email
         * @param {null|string} password
         * @returns {Promise}
         */
        signInUser = ({
            email,
            password,
        }) => (
            Auth.signIn({
                username: email.toLowerCase(),
                password: password,
            })
                .then(data => {
                    this.setAuthenticatedUser(data)
                    this.setAuthenticatedUserRoles(data)
                    this.redirectAfterSignIn()
                })
                .catch(err => Promise.reject(err))
        )

        /**
         * Signs out currently authenticated user
         */
        signOutUser = () => {
            Auth.signOut()
                .then(() => {
                    localStorage.removeItem('cognito.user')
                    localStorage.removeItem('cognito.isAdmin')
                    localStorage.removeItem('cognito.isVideoUploader')
                    window.location.href = '/login'
                })
                .catch(err => console.error(err))
        }

        /**
         * Triggers forgot password flow
         * @param {string} email
         * @returns {Promise<void>}
         */
        forgotPassword = email => Auth.forgotPassword(email)

        /**
         * Gets called on submitting new password at the end of password flow
         * @param {string} email
         * @param {string} code
         * @param {string} newPassword
         * @returns {Promise<void>}
         */
        forgotPasswordSubmit = (email, code, newPassword) => (
            Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword)
                .then(() => this.signInUser({
                    email: email,
                    password: newPassword,
                }))
        )

        /**
         * Updates user attributes
         * @param {Object} attributes
         * @returns {Promise<string>}
         */
        updateAuthUserAttributes = attributes => (
            Auth.currentAuthenticatedUser()
                .then(user => Auth.updateUserAttributes(user, attributes))
                .then(() => Auth.currentAuthenticatedUser())
                .then(updatedUser => this.setAuthenticatedUser(updatedUser))
        )

        /**
         * Updates many cognito auth user attributes
         * @param {Object} attributes
         * @returns {Promise<string>}
         */
        updateAuthUserManyAttributes = attributes => (
            this.updateAuthUserAttributes({
                ...attributes,
                'email_verified': true,
            })
        )

        /**
         * Updates user full name
         * @param {string} givenName
         * @param {string} familyName
         * @returns {Promise<string[]>}
         */
        updateUserFullName = (givenName, familyName) => (
            this.updateAuthUserManyAttributes({
                given_name: givenName,
                family_name: familyName,
            })
        )

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                signUpUser={this.signUpUser}
                signInUser={this.signInUser}
                signOutUser={this.signOutUser}
                forgotPassword={this.forgotPassword}
                forgotPasswordSubmit={this.forgotPasswordSubmit}
                updateAuthUserAttributes={this.updateAuthUserAttributes}
                updateUserFullName={this.updateUserFullName}
                { ...this.props }
            />
        )
    }

    childComponent.propTypes = {
        history: PropTypes.object.isRequired,
    }

    return withRouter(childComponent)
}

export default withAuth
