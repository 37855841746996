import React from 'react'

class FormComponent extends React.Component {
    /**
     * Sets message test
     * @param {Object} event
     * @return {*}
     */
    handleChange = event => (
        this.setState({ [event.target.name]: event.target.value })
    )

    /**
     * Handles date change
     * @param {string} key
     * @param {string} date
     */
    handleDateChange = (key, date) => {
        this.setState({ [key]: date })
    }

    /**
     * Handles checkbox changes
     * @param {Object} event
     */
    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: !!event.target.checked })
    }

    /**
     * Handles image change
     * @param {string} key
     * @param {Object} event
     */
    handleImageChange = (key, event) => {
        const files = event.target.files

        if (files && files.length > 0) {
            this.setState({ [key]: files[0] })
        }
    }

    /**
     * On change save file to state
     * @param {Object} event
     */
    handleFileChange = event => {
        const files = event.target.files

        if (files && files.length > 0) {
            this.setState({ file: files[0] })
        }
    }

    /**
     * Handles multiselect change
     * @param {string} key
     * @param {array} selected
     */
    handleMultiselectChange = (key, selected) => {
        this.setState({ [key]: selected })
    }

    /**
     * Maps multiselect input to values
     * @param {array} multiselectInput
     * @returns {array}
     */
    mapMultiselectToValues = multiselectInput => multiselectInput?.map(singleInput => singleInput.value) || []

    /**
     * Maps array to multiselect
     * @param {array} array
     * @returns {array}
     */
    mapArrayToMultiselect = array => array?.map(item => ({
        value: item,
        label: item,
    })) || []
}

export default FormComponent
