import gql from 'graphql-tag'

/**
 * Subscribes to stream chat message addition
 * @type {string}
 */
export const STREAM_CHAT_MESSAGE_ADDED = gql`
    subscription streamChatMessageAdded($stream_pk: String!) {
        streamChatMessageAdded(stream_pk: $stream_pk) {
            id
            stream_pk
            user_sub
            user_given_name
            user_family_name
            user_roles
            message
            created_at
        }
    }
`

/**
 * Subscribes to stream chat message deletion
 * @type {string}
 */
export const STREAM_CHAT_MESSAGE_DELETED = gql`
    subscription streamChatMessageDeleted($stream_pk: String!) {
        streamChatMessageDeleted(stream_pk: $stream_pk) {
            id
            stream_pk
            user_sub
            user_given_name
            user_family_name
            user_roles
            message
            created_at
        }
    }
`

/**
 * Subscribes to stream chat message ban
 * @type {string}
 */
export const STREAM_CHAT_MESSAGE_BANNED = gql`
    subscription streamChatMessageBanned($stream_pk: String!) {
        streamChatMessageBanned(stream_pk: $stream_pk) {
            id
            stream_pk
            user_sub
            user_given_name
            user_family_name
            user_roles
            message
            created_at
        }
    }
`

/**
 * Subscribes to stream chat message addition
 * @type {string}
 */
export const STREAM_LIVE_STATUS_UPDATED = gql`
    subscription streamLiveStatusUpdated($pk: String!) {
        streamLiveStatusUpdated(pk: $pk) {
            pk
        }
    }
`
