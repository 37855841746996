import React from 'react'

import './../form.sass'

const Index = ({
    handleSubmit,
    handleChange,
    errorMessage,
    isLoading,
}) => (
    <form onSubmit={handleSubmit} className="form-white form-float">
        <div className="form-float-field">
            <label htmlFor="">Email</label>
            <input className="form-control" type="email" name="email" onInput={handleChange} required/>
        </div>
        <div className="form-float-field">
            <label htmlFor="">Heslo</label>
            <input className="form-control" type="password" name="password" onInput={handleChange} required/>
        </div>
        {errorMessage &&
            <p className="text-danger mt-1 text-center">{ errorMessage }</p>
        }
        <div className="text-center">
            {!isLoading &&
                <input className="btn btn-purple mt-4 w-50" type="submit" value={'Přihlásit'} />
            }
            {isLoading &&
                <button className="btn btn-purple mt-4 w-50" disabled>
                    <div className="spinner-border spinner-border-sm" role="status" />
                </button>
            }
        </div>
    </form>
)

export default Index
