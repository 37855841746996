import React from 'react'
import appSyncClient from '../../appsync-client'
import publicAppSyncClient from '../../appsync-client-public'
import store from '../../store'

const withGraphql = WrappedComponent => (
    class extends React.Component {
        /**
         * Parses graphQL error
         * @param {string} message
         * @returns {Promise<never>}
         */
        parseGraphqlError = message => {
            try {
                message = JSON.parse(message)
                return Promise.reject(message)
            } catch (exception) {
                return Promise.reject(message)
            }
        }

        /**
         * Returns appsync graphql error mapped into an object
         * @param {string} error
         * @return {Promise|void} promise error rejection
         */
        mapGraphqlError = error => {
            if (!error.message || error?.errorType === 'Lambda:Unhandled') {
                return console.error(error)
            }

            if (error.message.startsWith('GraphQL error: ')) {
                return this.parseGraphqlError(error.message.slice(15))
            }

            return this.parseGraphqlError(error.message)
        }

        /**
         * Fires appsync mutation
         * @param {Object} input
         * @returns {*}
         */
        appsyncMutation = ({
            name,
            mutation,
            variables,
        }) => {
            const auth = store.getState()?.auth?.user
            const client = auth ? appSyncClient : publicAppSyncClient

            return client.mutate({
                mutation: mutation,
                variables: variables,
            })
                .then(data => data.data?.[name])
                .catch(error => Promise.reject(this.mapGraphqlError(error)))
        }

        /**
         * Fires appsync query
         * @param {Object} input
         * @returns {*}
         */
        appsyncQuery = ({
            name,
            query,
            variables,
        }) => {
            const auth = store.getState()?.auth?.user
            const client = auth ? appSyncClient : publicAppSyncClient

            return client.query({
                query: query,
                variables: variables,
            })
                .then(data => data.errors ? this.mapGraphqlError(data.errors[0]) : data.data?.[name])
                .catch(error => Promise.reject(this.mapGraphqlError(error)))
        }

        /**
         * Render child component with the injected methods
         * @returns {*}
         */
        render = () => (
            <WrappedComponent
                mapGraphqlError={this.mapGraphqlError}
                appsyncMutation={this.appsyncMutation}
                appsyncQuery={this.appsyncQuery}
                {...this.props}
            />
        )
    }
)

export default withGraphql
