import AWSAppSyncClient from 'aws-appsync'
import config from './config'

export default new AWSAppSyncClient({
    url: config('REACT_APP_APPSYNC_URL'),
    region: config('REACT_APP_AWS_REGION'),
    auth: {
        type: 'AWS_IAM',
        credentials: {
            accessKeyId: config('REACT_APP_APPSYNC_ID'),
            secretAccessKey: config('REACT_APP_APPSYNC_SECRET'),
        },
    },
    cacheOptions: {
        addTypename: false,
    },
    disableOffline: true,
}, {
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    },
})
