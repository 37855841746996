import React from 'react'
import PropTypes from 'prop-types'
import withAuth from '../wrappers/with-auth'
import { Link } from 'react-router-dom'

const Index = ({ signOutUser }) => (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to={'/streams'}>Streamio Admin Panel</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link className="nav-link" to={'/streams'}>Streamy</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={'/videos'}>Videa</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={'/channels'}>Kanály</Link>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <span className="nav-link" onClick={() => signOutUser()}>Odhlásit se</span>
                </li>
            </ul>
        </div>
    </nav>
)

Index.propTypes = {
    signOutUser: PropTypes.func.isRequired,
}

export default withAuth(Index)
