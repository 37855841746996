/**
 * Cognito admins group
 * @type {string}
 */
export const COGNITO_GROUP_ADMINS = 'Admins'

/**
 * Cognito video uploaders group
 * @type {string}
 */
export const COGNITO_GROUP_VIDEO_UPLOADERS = 'VideoUploaders'
