import gql from 'graphql-tag'

/**
 * Loads stream activation codes
 * @type {string}
 */
export const LOAD_STREAM_ACTIVATION_CODES = gql`
    mutation loadStreamActivationCodes($streamPk: String!, $key: String!, $codeType: ActivationCodeType!) {
        loadStreamActivationCodes(streamPk: $streamPk, key: $key, codeType: $codeType)
    }
`

/**
 * Stores new stream chat message
 * @type {string}
 */
export const STORE_STREAM_CHAT_MESSAGE = gql`
    mutation storeStreamChatMessage($input: StreamChatMessageInput!) {
        storeStreamChatMessage(input: $input) {
            id
            stream_pk
            user_sub
            user_given_name
            user_family_name
            user_roles
            message
            created_at
        }
    }
`

/**
 * Deletes existing stream chat message
 * @type {string}
 */
export const DELETE_STREAM_CHAT_MESSAGE = gql`
    mutation deleteStreamChatMessage($id: String!) {
        deleteStreamChatMessage(id: $id) {
            id
            stream_pk
            user_nickname
            user_given_name
            user_family_name
            user_roles
            message
            user_sub
            created_at
            updated_at
        }
    }
`

/**
 * Deletes existing stream chat message
 * @type {string}
 */
export const BAN_STREAM_CHAT_MESSAGE = gql`
    mutation banStreamChatMessage($id: String!) {
        banStreamChatMessage(id: $id) {
            id
            stream_pk
            user_nickname
            user_given_name
            user_family_name
            user_roles
            message
            user_sub
            created_at
            updated_at
        }
    }
`

/**
 * Publishes stream record
 * @type {string}
 */
export const PUBLISH_STREAM_RECORD = gql`
    mutation publishStreamRecord($input: StreamRecordVideoInput!) {
        publishStreamRecord(input: $input) {
            pk
            name
            name_en
            thumbnail
        }
    }
`

/**
 * Creates new stream simulcast
 * @return {string}
 */
export const STORE_STREAM_SIMULCAST = gql`
    mutation mutation($input: StreamSimulcastInput!) {
        storeStreamSimulcast(input: $input) {
            id
            url
            stream_key
            passthrough
            status
        }
    }
`

/**
 * Deletes stream simulcast
 * @return {string}
 */
export const DELETE_STREAM_SIMULCAST = gql`
    mutation mutation($streamPk: String!, $simulcastId: String!) {
        deleteStreamSimulcast(streamPk: $streamPk, simulcastId: $simulcastId)
    }
`
